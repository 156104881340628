import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFingerprint } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

const UniqueItem = ({ className }) => (
  <div className={classNames("inline-flex items-center", className)}>
    <FontAwesomeIcon icon={faFingerprint} size="2x" className="mr-3" />
    <span className="text-sm border-l-2 pl-3">
      This is a unique, one of a kind item.
      <br />
      There is only one available.
    </span>
  </div>
);

export default UniqueItem;
