export const useMetafields = (metafields) => {
  const hasMetafield = (key) =>
    metafields.find((metafield) => metafield.key === key) ? true : false;

  const getMetafield = (key) => {
    let found = metafields.find((metafield) => metafield.key === key);

    if (found) {
      return found.value;
    }

    return false;
  };

  const getMetafieldsByProductId = (id) =>
    metafields.filter(({ productId }) => productId === id);

  const isUniqueItem = () =>
    metafields.find(
      ({ key, value }) => key === "unique_item" && value === "true"
    );

  return {
    hasMetafield,
    getMetafield,
    getMetafieldsByProductId,

    isUniqueItem: isUniqueItem(),

    hasTowelMaterial: hasMetafield("towel_material"),
    getTowelMaterial: () => getMetafield("towel_material"),

    hasQuiltSize: hasMetafield("quilt_size"),
    getQuiltSize: () => getMetafield("quilt_size"),

    hasQuiltDimensions: hasMetafield("quilt_dimensions"),
    getQuiltDimensions: () => JSON.parse(getMetafield("quilt_dimensions")),

    hasMaxQty: hasMetafield("max_quantity"),
    getMaxQty: () => parseInt(getMetafield("max_quantity")),

    hasNotes1: hasMetafield("notes_1"),
    getNotes1: () => getMetafield("notes_1"),
    isNotes1Required: getMetafield("notes_1_required") === "true",

    hasNotes2: hasMetafield("notes_2"),
    getNotes2: () => getMetafield("notes_2"),
    isNotes2Required: getMetafield("notes_2_required") === "true",

    hasNotes3: hasMetafield("notes_3"),
    getNotes3: () => getMetafield("notes_3"),
    isNotes3Required: getMetafield("notes_3_required") === "true",
  };
};
