import React from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

const QuantityInput = ({
  onIncrement,
  onDecrement,
  className,
  inputClassName,
  quantity,
  disabled,
  max,
  min,
  ...props
}) => {
  const disableIncrement = parseInt(quantity) >= parseInt(max);
  const disableDecrement = parseInt(quantity) <= parseInt(min);

  return (
    <div className={classNames("flex flex-col", className)}>
      <div className={classNames("input input-quantity", inputClassName)}>
        <input
          disabled={disabled}
          type="numeric"
          value={quantity}
          max={max}
          min={min}
          {...props}
        />
        <button
          disabled={disabled || disableIncrement}
          className="increment"
          aria-label="Increment"
          onClick={onIncrement}
        >
          <span>+</span>
          <FontAwesomeIcon icon={faAngleUp} />
        </button>
        <button
          disabled={disabled || disableDecrement}
          className="decrement"
          aria-label="Decrement"
          onClick={onDecrement}
        >
          <span>-</span>
          <FontAwesomeIcon icon={faAngleDown} />
        </button>
      </div>
      {/* {max && <span className="text-sm">Max of {max}</span>} */}
    </div>
  );
};

export default QuantityInput;
