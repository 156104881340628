import React, { useContext, useState, useCallback, useMemo } from "react";
import debounce from "lodash.debounce";
import { GatsbyImage } from "gatsby-plugin-image";
import { getShopifyImage } from "gatsby-source-shopify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import { formatCurrency } from "../utils/utils";
import { StoreContext } from "../utils/store-context";
import { useMetafields } from "../hooks/useMetafields";

import QuantityInput from "./quantityInput";
import UniqueItem from "./uniqueItem";

export function LineItem({ item }) {
  const { removeLineItem, checkout, updateLineItem, loading } =
    useContext(StoreContext);
  const [quantity, setQuantity] = useState(item.quantity);
  const productMeta = useMetafields(item.variant.product.metafields);
  const variantMeta = useMetafields(item.variant.metafields);

  const variantImage = useMemo(
    () =>
      item.variant.image
        ? {
            ...item.variant.image,
            originalSrc: item.variant?.image?.src,
          }
        : null,
    [item.variant.image]
  );
  const price = formatCurrency(
    Number(item.variant.priceV2.amount),
    item.variant.priceV2.currencyCode
  );

  const subtotal = formatCurrency(
    Number(item.variant.priceV2.amount) * quantity,
    item.variant.priceV2.currencyCode
  );

  const variantTitle =
    item.variant.title === "Default Title" ? "" : item.variant.title;

  const handleRemove = () => {
    removeLineItem(checkout.id, item.id);
  };

  const uli = debounce(
    (value) => updateLineItem(checkout.id, item.id, value),
    1000
  );
  // eslint-disable-next-line
  const debouncedUli = useCallback((value) => uli(value), []);

  const handleQuantityChange = (value) => {
    if (value !== "" && Number(value) < 1) {
      return;
    }

    if (value < minQty) {
      handleQuantityChange(minQty);
      return;
    } else if (value > maxQty) {
      handleQuantityChange(maxQty);
      return;
    }

    setQuantity(value);
    if (Number(value) >= 1) {
      debouncedUli(value);
    }
  };

  const image = useMemo(
    () =>
      variantImage
        ? getShopifyImage({
            image: variantImage,
            // layout: "constrained",
            crop: "contain",
            width: 160,
            height: 160,
          })
        : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variantImage]
  );

  const minQty = 1;
  const maxQty = variantMeta.hasMaxQty
    ? variantMeta.getMaxQty()
    : productMeta.getMaxQty() || 20;

  return (
    <div className="flex flex-col md:flex-row p-4 mb-4 border-2 border-studio-charcoal">
      <div className="mr-4">
        {image && (
          <GatsbyImage
            key={variantImage.src}
            image={image}
            alt={variantImage.altText ?? item.variant.title}
          />
        )}
      </div>
      <div className="flex-grow">
        <div className="flex justify-between align-start mb-2">
          <div>
            <span className="font-bold font-new-tegomin text-2xl">
              {item.title}
            </span>
            {variantTitle && (
              <>
                <br />
                <span>{variantTitle}</span>
              </>
            )}
            {item.customAttributes.map((attr) => (
              <CustomAttribute key={attr.key} attribute={attr} />
            ))}
          </div>
        </div>
        <span className="block text-lg mb-1">Price: {price}</span>
        <div className="flex items-start gap-4 mb-2">
          {!productMeta.isUniqueItem ? (
            <QuantityInput
              disabled={loading}
              quantity={quantity}
              aria-label="Quantity"
              onIncrement={() => {
                handleQuantityChange(Math.min(quantity + 1, maxQty));
              }}
              onDecrement={() => {
                handleQuantityChange(Math.max(minQty, quantity - 1));
              }}
              onChange={(e) => handleQuantityChange(e.currentTarget.value)}
              // onBlur={(e) => {
              //   let qty = e.currentTarget.value;
              //   if (qty < minQty) {
              //     handleQuantityChange(minQty);
              //   } else if (qty > maxQty) {
              //     handleQuantityChange(maxQty);
              //   }
              // }}
              min={minQty}
              max={maxQty}
            />
          ) : (
            <UniqueItem className="!mb-0" />
          )}
          <button
            className="btn btn-outline-red no-zoom"
            onClick={handleRemove}
          >
            <FontAwesomeIcon icon={faTrash} /> Remove
          </button>
        </div>
        <div>
          <span className="block text-right text-xl">
            <span className="font-bold">Item Total:</span> {subtotal}
          </span>
        </div>
      </div>
    </div>
  );
}

const CustomAttribute = ({ attribute }) => (
  <>
    <br />
    <span className="font-bold">{attribute.key}: </span>
    {attribute.value}
  </>
);
