import React, { useContext } from "react";
import { Link, graphql } from "gatsby";

import { formatCurrency } from "../utils/utils";
import { StoreContext } from "../utils/store-context";
import Layout from "../components/layout";
import PageInfo from "../components/pageInfo";
import { LineItem } from "../components/lineItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const Cart = ({ data }) => {
  const pageTitle = "Cart";

  const { checkout, loading } = useContext(StoreContext);
  const emptyCart = checkout.lineItems.length === 0;

  const cart = Array.from(checkout.lineItems).map((lineItem) => {
    let newLineItem = { ...lineItem };
    let newVariant = { ...newLineItem.variant };

    let variant = null;
    let product = data.allShopifyProduct.nodes.find((product) => {
      variant = product.variants.find(
        (variant) => variant.shopifyId === lineItem.variant.id
      );

      return variant;
    });

    newVariant.metafields = variant.metafields;
    newVariant.product = { ...product };
    newLineItem.variant = newVariant;

    return newLineItem;
  });

  const handleCheckout = () => {
    window.open(checkout.webUrl);
  };

  return (
    <Layout>
      <PageInfo title={pageTitle} />
      <h1 className="text-6xl text-center mb-10 font-new-tegomin">
        {pageTitle}
      </h1>

      {emptyCart ? (
        <>
          <h2 className="text-center">Your cart is empty.</h2>
          <p className="text-center">
            It's time to fill up your cart! Checkout out some of our{" "}
            <Link to="/products/">products</Link>.
          </p>
        </>
      ) : (
        <>
          <p className="text-center text-2xl mb-10">
            <span className="font-bold">Not done shopping?</span> Checkout out
            some of our other <Link to="/products/">products</Link>.
          </p>

          {loading && (
            <p className="text-center text-xl">
              <FontAwesomeIcon icon={faSpinner} spin /> Updating your cart...
            </p>
          )}

          <div
            className="flex flex-col lg:flex-row items-start mx-auto"
            style={{ maxWidth: 1200 }}
          >
            <div className="w-full lg:w-3/4 p-4 pb-0 lg:mr-6 mb-6 bg-gray-600">
              {cart.map((item) => (
                <LineItem key={item.id} item={item} />
              ))}
            </div>

            <div className="flex flex-col gap-3 w-full lg:w-1/4 border-2 border-gray-600 p-4">
              <div className="flex justify-between">
                <span className="font-bold">Subtotal</span>
                <span>
                  {formatCurrency(
                    checkout.lineItemsSubtotalPrice.amount,
                    checkout.lineItemsSubtotalPrice.currencyCode
                  )}
                </span>
              </div>
              {checkout.discountApplications.length > 0 && (
                <div>
                  <span className="font-bold">Discounts</span>
                </div>
              )}
              {checkout.discountApplications.length > 0 &&
                checkout.discountApplications.map(({ code, value }) => (
                  <div className="flex justify-between">
                    <span>{code}</span>
                    <span>
                      -{formatCurrency(value.amount, value.currencyCode)}
                    </span>
                  </div>
                ))}
              <div className="flex justify-between">
                <span className="font-bold">Taxes</span>
                <span>
                  {formatCurrency(
                    checkout.totalTaxV2.amount,
                    checkout.totalTaxV2.currencyCode
                  )}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="font-bold">Shipping</span>
                <span className="text-right">
                  {checkout.shippingLine
                    ? formatCurrency(
                        checkout.shippingLine.priceV2.amount,
                        checkout.shippingLine.priceV2.currencyCode
                      )
                    : "Calculated at checkout"}
                </span>
              </div>
              <hr />
              <div className="flex justify-between">
                <span className="font-bold">Total Price</span>
                <span>
                  {formatCurrency(
                    checkout.totalPriceV2.amount,
                    checkout.totalPriceV2.currencyCode
                  )}
                </span>
              </div>
              <button
                onClick={handleCheckout}
                disabled={loading}
                className="btn btn-primary no-zoom self-stretch"
              >
                Checkout
              </button>
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

export default Cart;

export const query = graphql`
  query {
    allShopifyProduct {
      nodes {
        variants {
          shopifyId
          metafields {
            key
            value
          }
        }
        metafields {
          key
          value
        }
      }
    }
  }
`;
